import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps, Grid, Link as MuiLink } from '@material-ui/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Container, { ContainerProps } from 'system/components/Container';

export default function FooterLinks(props: ContainerProps & BoxProps) {
  const { t } = useTranslation('static_pages');
  const router = useRouter();
  const cities = [
    {
      key: 'berlin',
      link: 'berlin',
    },
    {
      key: 'muenchen',
      link: 'muenchen',
    },
    {
      key: 'hamburg',
      link: 'hamburg',
    },
    {
      key: 'frankfurt-am-main',
      link: 'frankfurt',
    },
    {
      key: 'duesseldorf',
      link: 'duesseldorf',
    },
    {
      key: 'koeln',
      link: 'koeln',
    },
    {
      key: 'leipzig',
      link: 'leipzig',
    },
    {
      key: 'hannover',
      link: 'hannover',
    },
  ];

  const countries = ['austria', 'germany', 'switzerland'];
  const countriesLinksMapping = {
    switzerland: 'schweiz',
    germany: 'deutschland',
    austria: 'oesterreich',
  };

  const cateringCategories = [
    { key: 'fingerfood', url: '/fingerfood' },
    { key: 'breakfast', url: '/fruehstueck' },
    { key: 'foodTruck', url: '/food-truck' },
    { key: 'event', url: '/event' },
    { key: 'buffet', url: '/buffet' },
    { key: 'cocktail', url: '/bar-cocktail' },
    { key: 'coffee', url: '/mobile-kaffeebar' },
    { key: 'vegan', url: '/vegan' },
    { key: 'bbq', url: '/grill-catering' },
    { key: 'summer_party', url: '/sommerfest' },
    { key: 'company_party', url: '/catering-firmenfeier' },
  ];

  return (
    <Container as="nav" {...props}>
      <Box pb={2} pt={4} id="footerLinksSection">
        <section>
          <Typography variant="h6" component="h6" mb={3}>
            {t('footerLinks.cateringCategories')}
          </Typography>
          <Box pl={1}>
            <StyledGrid container spacing={1}>
              {cateringCategories.map((category) => (
                <Grid item key={category.key} md={3} sm={6} xs={12}>
                  <Link
                    href={`/${router.locale}${category.url}`}
                    passHref
                    locale={false}
                  >
                    <MuiLink variant="body2" color="textPrimary">
                      {t(`footerLinks.categories.${category.key}`)}
                    </MuiLink>
                  </Link>
                </Grid>
              ))}
            </StyledGrid>
          </Box>
        </section>
        <section>
          <Typography variant="h6" component="h6" mt={5} mb={3}>
            {t('footerLinks.cities')}
          </Typography>
          <Box pl={1}>
            <StyledGrid container spacing={1}>
              {cities.map((city) => (
                <Grid item key={city.link} md={3} sm={6} xs={12}>
                  <Link
                    href={`/${router.locale}/catering-${city.link}`}
                    passHref
                    locale={false}
                  >
                    <MuiLink key={city.key} variant="body2" color="textPrimary">
                      {t('footerLinks.cateringIn', {
                        city: t(`common:cities.${city.key}`),
                      })}
                    </MuiLink>
                  </Link>
                </Grid>
              ))}
            </StyledGrid>
          </Box>
        </section>
        <section>
          <Typography variant="h6" component="h6" mt={5} mb={3}>
            {t('footerLinks.countries')}
          </Typography>
          <Box pl={1}>
            <StyledGrid container spacing={1}>
              {countries.map((country) => (
                <Grid item key={country} md={3} sm={6} xs={12}>
                  <Link
                    href={`/${router.locale}/catering-${
                      countriesLinksMapping[
                        country as keyof typeof countriesLinksMapping
                      ]
                    }`}
                    passHref
                    locale={false}
                  >
                    <MuiLink key={country} variant="body2" color="textPrimary">
                      {t(`common:countries.${country}`)}
                    </MuiLink>
                  </Link>
                </Grid>
              ))}
            </StyledGrid>
          </Box>
        </section>
      </Box>
    </Container>
  );
}

const StyledGrid = styled(Grid)`
  padding: ${(props) => props.theme.spacing(0, 0, 0, 1)};
`;
